<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="800"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <!-- Radio Buttons Row -->
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-radio-group v-model="usertype" row>
                  <v-radio label="Groupe" value="group"></v-radio>
                  <v-radio label="Utilisateur" value="user"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="8" md="8" v-if="usertype === 'user'">
                <v-select
                  v-model="editedItem.user_id"
                  :items="users"
                  label="Utilisateur"
                  :rules="[(v) => !!v || 'Utilisateur obligatoire']"
                  item-value="id"
                  item-text="name"
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="8" md="8" v-if="usertype === 'group'">
                <v-select
                  v-model="editedItem.group_id"
                  :items="groups"
                  item-value="id"
                  label="Groupe"
                  item-text="name"
                  :rules="[(v) => !!v || 'Groupe obligatoire']"
                  chips
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <!-- Checkboxes Row -->
            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="editedItem.edit_access"
                  label="Edition"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="editedItem.delete_access"
                  label="Suppression"
                  dense
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="editedItem.download_access"
                  label="Téléchargement"
                  dense
                  v-if="document.isurl === 0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="save()"
              >Enregistrer</v-btn
            >
            <v-btn color="blue darken-1" text @click.stop="close">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import UPDATE_DOC_PERMISSION from "../graphql/File/UPDATE_DOC_PERMISSION.gql";
import CREATE_DOC_PERMISSION from "../graphql/File/CREATE_DOC_PERMISSION.gql";

export default {
  components: {},
  name: "fileform",
  props: {
    item: Object,
    items: Array,
    document: Object,
    showForm: Boolean,
    users: Array,
    groups: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    chosenFiles: [],
    permissions: [],
    usertype: "group",
  }),

  computed: {
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Permission "
          : "Permission : ".concat(this.item.filename);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      if (this.editedItem.id > 0)
        this.usertype = this.editedItem.group_id ? "group" : "user";
    }
  },

  methods: {
    OpenPermissionForm() {},

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let l = this.usertype === "group" ? this.groups : this.users;
        let i = l.findIndex(
          (elm) =>
            elm.id ==
            (this.usertype === "group"
              ? this.editedItem.group_id
              : this.editedItem.user_id)
        );
        if (i >= 0) {
          this.editedItem.user_name = l[i].name;
          this.editedItem.permission_color =
            this.usertype === "group" ? l[i].color : "Gray";
        }
        this.editedItem.permission_type = this.usertype === "group" ? "G" : "U";
        this.editedItem.canRead = true;
        this.editedItem.canWrite = this.editedItem.edit_access == 1;
        this.editedItem.canDelete = this.editedItem.delete_access == 1;
        this.editedItem.canDownload = this.editedItem.download_access == 1;
        if (this.editedItem.id > 0) {
          let r = await this.maj(UPDATE_DOC_PERMISSION, {
            docpermission: {
              id: this.editedItem.id,
              group_id:
                this.usertype === "group" ? this.editedItem.group_id : null,
              user_id:
                this.usertype === "user" ? this.editedItem.user_id : null,
              edit_access: this.editedItem.edit_access ? 1 : 0,
              delete_access: this.editedItem.delete_access ? 1 : 0,
              download_access: this.editedItem.download_access ? 1 : 0,
              write_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.items.splice(this.item.index, 1, this.editedItem);
          }
        } else {
          let r = await this.maj(CREATE_DOC_PERMISSION, {
            docpermission: {
              doc_id: this.document.id,
              group_id:
                this.usertype === "group" ? this.editedItem.group_id : null,
              user_id:
                this.usertype === "user" ? this.editedItem.user_id : null,
              edit_access: this.editedItem.edit_access ? 1 : 0,
              delete_access: this.editedItem.delete_access ? 1 : 0,
              download_access: this.editedItem.download_access ? 1 : 0,
              create_uid: this.$store.state.me.id,
            },
          });
          if (r) {
            this.items.push(this.editedItem);
          }
        }
        this.$emit("changed");
        this.close();
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
